<template>
    <div>
        <h1 class="bottom-0">Form Builder</h1>
        <p class="top-0 bottom-30">Begin building your form</p>
        <h3 class="bottom-5">Rehydrate Code:</h3>
        <textarea class="bottom-20" v-model="beginningCodeHandler"></textarea>
        <FormField
            class="card-block"
            v-for="(field, idx) in inputData"
            :key="field.id"
            :idx="idx"
            @removeField="removeField"
            @addLi="addLi"
            @removeLi="removeLi"
            @changeType="changeType"
            @setAutoFields="setAutoFields"
            @moveFieldUp="moveFieldUp"
            @moveFieldDown="moveFieldDown"
            :field="field"
            :types="types"
            :length="inputData.length"
            :ref="field.id"
        />
        <button class="primary top-10" @click="addField">Add Field</button>
      <br/>
      <hr/>
        <div class="card-block">
            <h1>Template</h1>
            <button @click="copyToClipboard('htmlOutput')" class="primary">Copy</button>
            <code> {{ htmlOutput }}</code>
        </div>
        <div class="card-block">
            <h1>JSON</h1>
            <button @click="copyToClipboard('jsonOutput')" class="primary">Copy</button>
            <code> {{ jsonOutput }}</code>
        </div>
        <div class="card-block">
            <h1>JS Array</h1>
            <button @click="copyToClipboard('inputData')" class="primary">Copy</button>
            <code> {{ stringInputData }}</code>
        </div>
      <hr/>
      <FormPreview class="" :form="inputData" />
    </div>
</template>

<script>
    import { generateId } from '@/util/genericUtilityFunctions';

    import FormField from '@/pages/dev/FormField';
    import FormPreview from "@/pages/dev/FormPreview";

    export default {
        name: 'FormBuilder.vue',
        components: {
          FormPreview,
            FormField
        },
        data() {
            return {
                beginningCode: '',
                htmlOutput: '',
                jsonOutput: {},
                inputData: [{ type: 'h1', text: 'Welcome to Form Builder', id: generateId(), field: 'welcome', itemText: [{ id: generateId(), text: '' }] }],
                types: ['h1', 'h3', 'p', 'ul', 'ol', 'input', 'textarea', 'select', 'checkbox', 'radio', 'button', 'submit', 'date', 'multiselect']
            };
        },
        methods: {
            async addField() {
                const id = generateId();
                this.inputData.push({
                    type: 'input',
                    id,
                    field: '',
                    text: '',
                    defaultValue: '',
                    placeholder: '',
                    if: '',
                    itemText: [{ text: '' }]
                });

                await this.$nextTick(() => {
                    this.$refs[id][0].setFocus();
                });
            },
            removeField(id) {
                this.inputData = this.inputData.filter((field) => field.id !== id);
            },
            addLi(idx) {
                const id = generateId();
                this.inputData[idx].itemText.push({ id: id, text: '' });
            },
            removeLi(idx) {
                this.inputData[idx].itemText.pop();
            },
            changeType(id, type) {
                this.inputData = this.inputData.map((field) => {
                    if (field.id === id) {
                        const { id, text } = field;
                        let basics = { id, type, text: text || '' };
                        switch (type) {
                            case 'h1':
                                return basics;

                            case 'h3':
                                return basics;

                            case 'p':
                                return basics;

                            case 'ul':
                                return { ...basics, itemText: [{ id: generateId(), text: ''}] };

                            case 'ol':
                                return { ...basics, itemText: [{ id: generateId(), text: ''}] };

                            case 'checkbox':
                                return { ...basics, field: '', defaultValue: '', placeholder: '', if: '' };

                            case 'radio':
                                return { ...basics, field: '', options: '', defaultValue: '', placeholder: '', if: '' };

                            case 'select':
                                return { ...basics, field: '', options: '', defaultValue: '', placeholder: '', if: '' };

                            case 'input':
                                return {
                                    ...basics,
                                    field: '',
                                    defaultValue: '',
                                    if: '',
                                    placeholder: ''
                                };

                            case 'textarea':
                                return {
                                    ...basics,
                                    field: '',
                                    defaultValue: '',
                                    if: '',
                                    placeholder: ''
                                };

                            case 'date':
                                return {
                                    ...basics,
                                    field: '',
                                    defaultValue: '',
                                    if: ''
                                };
                            case 'multiselect':
                                return { ...basics, field: '', options: '', defaultValue: '', placeholder: '', if: '' };




                            default:
                                return { ...basics };
                        }
                    } else {
                        return field;
                    }
                });
            },

            setAutoFields(id, newField) {
                this.inputData = this.inputData.map((field) => {
                    if (field.id === id) {
                        return {
                            ...field,
                            field: newField
                        };
                    } else {
                        return field;
                    }
                });
            },
            moveFieldUp(id) {
                // swap with the previous field in the inputData array
                const newArray = [...this.inputData];
                const index = newArray.findIndex((field) => field.id === id);
                const temp = newArray[index];
                newArray[index] = newArray[index - 1];
                newArray[index - 1] = temp;
                this.inputData = newArray;
            },
            moveFieldDown(id) {
                // swap element in the inputData array with the id of the argument with the next field in the inputData array
                const newArray = [...this.inputData];
                const index = this.inputData.findIndex((field) => field.id === id);
                const nextField = this.inputData[index + 1];
                newArray[index + 1] = this.inputData[index];
                newArray[index] = nextField;
                this.inputData = newArray;
            },

            generateHTML() {
                let output = '';
                this.inputData.forEach((item) => {
                    switch (item.type) {
                        case 'h1':
                            output += '<h1>' + item.text + '</h1>';
                            break;
                        case 'h3':
                            output += '<h3>' + item.text + '</h3>';
                            break;
                        case 'p':
                            output += '<p>' + item.text + '</p>';
                            break;
                        case 'ul':
                            output += '<ul>';
                            for (let i of item.itemText) {
                                output += '<li>' + i.text + '</li>';
                            }
                            output += '</ul>';
                            break;
                        case 'ol':
                            output += '<ol>';
                            for (let i of item.itemText) {
                                output += '<li>' + i.text + '</li>';
                            }
                            output += '</ol>';
                            break;
                        case 'input':
                            output += `<Input type='text' label="${item.text}" v-model="rec.${item.field}" ${
                                item.placeholder ? 'placeholder="' + item.placeholder + '"' : ''
                            }  ${item.if ? 'v-if="' + item.if + '"' : ''}  />`;
                            break;
                        case 'date':
                            output += `<Input type='date' label="${item.text}" v-model="rec.${item.field}"  ${
                                item.if ? 'v-if="' + item.if + '"' : ''
                            }  >`;
                            break;
                        case 'select':
                            output += '<select v-model="rec.' + item.field + '">';
                            item.options.split(',').forEach((option) => {
                                output += '<option value=' + option + '>' + JSON.parse(option) + '</option>';
                            });
                            output += '</select>';
                            break;
                        case 'textarea':
                            output += `<textarea v-model="rec.${item.field}" ${
                                item.placeholder ? 'placeholder="' + item.placeholder + '"' : ''
                            }  ${item.if ? 'v-if="' + item.if + '"' : ''}  ></textarea>`;
                            break;
                        case 'checkbox':
                            output += `<Checkbox label="${item.text}" v-model="rec.${item.field}" ${
                                item.if ? 'v-if="' + item.if + '"' : ''
                            } >`;
                            break;
                        case 'radio':
                            output += `<Radios label="${item.text}" v-model="rec.${item.field}" ${
                                item.if ? 'v-if="' + item.if + '"' : ''
                            } :options='[${item.options}]' >`;
                            break;
                        case 'multiselect':
                            output += `<label for=''>${item.text}</label><Multiselect label="${item.toLabelBy}" v-model="rec.${item.field}" ${
                                item.if ? 'v-if="' + item.if + '"' : ''
                            } :options='[${item.options}]' />`;
                            break;


                    }
                });

                this.htmlOutput = output;
            },
            generateJSON() {
                let output = {};
                for (let field of this.inputData) {
                    if (['input', 'textarea', 'select', 'checkbox', 'radio', 'date', 'multiselect'].includes(field.type)) {
                        output[field.field] = field.defaultValue;
                    }
                }

                this.jsonOutput = JSON.stringify(output);
            },
            copyToClipboard(field) {
                navigator.clipboard
                    .writeText(typeof this[field] === 'string' ? this[field] : JSON.stringify(this[field]))
                    .then(() => {
                        this.$toasted.success('Copied code to your clipboard');
                    })
                    .catch((err) => console.log(err));
            }
        },
        computed: {
            stringInputData() {
                return JSON.stringify(this.inputData, null, 2);
            },
            beginningCodeHandler: {
                set(value) {
                    this.beginningCode = value;
                    if (value !== '') {
                        this.inputData = JSON.parse(value);
                    }
                },
                get() {
                    return this.beginningCode;
                }
            }
        },
        created() {
            this.generateHTML();
        },
        watch: {
            inputData: {
                deep: true,
                handler() {
                    this.generateHTML();
                    this.generateJSON();
                }
            }
        }
    };
</script>

<style scoped></style>