<template>
    <div class="top-10">
        <h3>Field {{idx+1 + ': - ' + field.id }}</h3>
        <button @click="removeField(field.id)" class="primary bottom-20" tabindex="1">Remove Field</button>
        <button @click="moveFieldUp" class="primary" v-if="idx !== 0" tabindex="1">Move Up</button>
        <button @click="moveFieldDown" class="primary" v-if="idx + 1 !== length" tabindex="1">Move Down</button>
        <div class="flex">
            <div class="right-15">
                <select class="" style="width: 120px;" :value="field.type" @change="changeType(field.id, $event.target.value)" ref="type">
                    <option v-for="type in types" :value="type" :key="type">{{ type }}</option>
                </select>
            </div>
            <!--          h1          -->
            <div class="fullwidth" v-if="field.type === 'h1'">
                <input type="text" v-model="field.text" placeholder="Text" />
            </div>
            <!--          h3          -->
            <div class="fullwidth" v-if="field.type === 'h3'">
                <input type="text" v-model="field.text" placeholder="Text" />
            </div>
            <!--          p          -->
            <div class="fullwidth" v-if="field.type === 'p'">
                <input type="text" v-model="field.text" placeholder="Text" />
            </div>
        

            <!--          ul          -->
            <div v-if="field.type === 'ul'">
                <div v-for="(item, iidx) in field.itemText" :key='item.id'>
                    <input type="text" v-model="field.itemText[iidx].text" placeholder="Text" />
                </div>
                <button @click="addLi(idx)" class="primary" tabindex="1">Add &lt;li&gt;</button>
                <button @click="removeLi(idx)" class="primary" tabindex="1">Remove &lt;li&gt;</button>
            </div>

            <!--          ol          -->
            <div v-if="field.type === 'ol'">
                <div v-for="(item, iidx) in field.itemText" :key='item.id'>
                    <input type="text" v-model="field.itemText[iidx].text" placeholder="Text" />
                </div>
                <button @click="addLi(idx)" class="primary" tabindex="1">Add &lt;li&gt;</button>
                <button @click="removeLi(idx)" class="primary" tabindex="1">Remove &lt;li&gt;</button>
            </div>

            <!--          input          -->
            <div v-if="field.type === 'input'">
                <Input class="pad-5" v-model="field.text" label="Text " @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " />
                <Input class="pad-5" v-model="field.placeholder" label="Placeholder " />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>

            <!--          textarea          -->
            <div v-if="field.type === 'textarea'">
                <Input class="pad-5" v-model="field.text" label="Text "  @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " />
                <Input class="pad-5" v-model="field.placeholder" label="Placeholder " />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>

            <!--          checkbox          -->
            <div v-if="field.type === 'checkbox'">
                <Input class="pad-5" v-model="field.text" label="Text "  @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>

            <!--          radio          -->
            <div v-if="field.type === 'radio'">
                <Input class="pad-5" v-model="field.text" label="Text "  @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model="field.options" label="Options " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " required />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>

            <!--          select          -->
            <div v-if="field.type === 'select'">
                <Input class="pad-5" v-model="field.text" label="Text "  @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model="field.options" label="Options " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " required />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>

            <!--          date          -->
            <div v-if="field.type === 'date'">
                <Input class="pad-5" v-model="field.text" label="Text "  @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>

            <!--        multiselect     -->
            <div v-if="field.type === 'multiselect'" >
                <Input class="pad-5" v-model="field.text" label="Text "  @blur="setField" />
                <Input class="pad-5" v-model="field.field" label="Field " required />
                <Input class="pad-5" v-model='field.toLabelBy' label='Property to
                label by ' required/>
                <Input class="pad-5" v-model="field.options" label="Options " required />
                <Input class="pad-5" v-model="field.defaultValue" label="Default " required />
                <Input class="pad-5" v-model="field.if" label="If " />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormField',
        props: {
            field: {
                type: Object,
                required: true
            },
            idx: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            types: {
                type: Array,
                required: true
            }
        },
        methods: {
            removeField(id) {
                this.$emit('removeField', id);
            },
            addLi(idx) {
                this.$emit('addLi', idx);
            },
            removeLi(idx) {
                this.$emit('removeLi', idx);
            },
            changeType(id, type) {
                this.$emit('changeType', id, type);
            },
            setField() {
                if (this.field.field === '') {
                    const field = this.field.text
                        .toLowerCase()
                        .replace(/ /g, '_')
                        .replace(/[^a-z0-9_]/g, '')
                        .substring(0, 20);
                    this.$emit('setAutoFields', this.field.id, field);
                }
            },
            moveFieldUp() {
                this.$emit('moveFieldUp', this.field.id);
            },
            moveFieldDown() {
                this.$emit('moveFieldDown', this.field.id);
            },
          setFocus(){
              this.$refs.type.focus()
          }
        }
    };
</script>

<style scoped></style>
