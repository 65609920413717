<template>
  <div>
    <h1 v-if="field.type === 'h1'">
        {{ field.text }}
    </h1>
    <h3 v-else-if="field.type === 'h3'">
        {{ field.text }}
    </h3>
    <p v-else-if="field.type === 'p'">
        {{ field.text }}
    </p>
    <ul v-else-if="field.type === 'ul'">
        <li v-for="item in field.itemText" :key="item.id">
            {{ item.text }}
        </li>
    </ul>
    <ol v-else-if="field.type === 'ol'">
        <li v-for="item in field.itemText" :key="item.id">
            {{ item.text }}
        </li>
    </ol>
    <Input
        v-else-if="field.type === 'input'"
        :id="field.id"
        :placeholder="field.placeholder"
        :label="field.text"
        @input="updateVal($event)"
        :value="value"
    />
    <TextArea
        v-else-if="field.type === 'textarea'"
        :id="field.id"
        :placeholder="field.placeholder"
        :label="field.text"
        @input="updateVal($event.target.value)"
        :value="value"
    />
    <Checkbox v-else-if="field.type === 'checkbox'"
           type="checkbox"
           :id="field.id"
           :label="field.text"
           :value="value"
           @input="updateVal($event)"
    />
    <Radios v-else-if="field.type === 'radio'"
           :id="field.id"
           :label="field.text"
           :options="field.options.split(',')"
           :value="value"
           @input="updateVal($event)"
    />



    <div v-else>Not yet created - {{field.type}}</div>
  </div>
</template>

<script>
    export default {
        name: 'RenderedFormField',
        props: {
            field: {
                type: Object,
                required: true
            },
            value: { type: [ String, Number, Object, Array, Boolean], required: false },
            hidden: {default:  false }
        },
        methods: {
            updateVal(event) {
                this.$emit('input', event);
            },
        }
    };
</script>