<template>
    <div>
        <h1>Form Preview</h1>
      <p>See below the break for your form</p>
      <hr />
        <RenderedFormField v-for="field in filteredForms" :key="field.id" :field="field" v-model="rec[field.field]" />
    </div>
</template>

<script>
    import RenderedFormField from './RenderedFormField.vue';

    export default {
        name: 'FormPreview',
        components: {
            RenderedFormField
        },
        props: {
            form: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                rec: {},
                filteredForms: []
            };
        },
        methods: {
            evaluate(code) {
              return true
                // return eval(code);
            },
            filterForms(form) {
                const filteredForms = form.filter((field) => {
                    return field.if ? this.evaluate(field.if) : true;
                });
                this.$set(this, 'filteredForms', filteredForms);
            }
        },
        watch: {
            form: {
                deep: true,
                handler: function(val) {
                    // map through val. if val is of an input type, then set a key and value on rec. keep any existing values of rec
                    let newRec = {};
                    val.forEach((field) => {
                        if (['input', 'number', 'email', 'password', 'textarea', 'radio'].includes(field.type)) {
                            newRec[field.field] =
                                typeof this.rec[field.field] === typeof field.defaultValue
                                    ? this.rec[field.field]
                                    : field.defaultValue;
                        } else if (['checkbox'].includes(field.type)) {
                            newRec[field.field] = this.rec[field.field] || field.defaultValue.toLowerCase()==='true';
                        }
                    });
                    this.$set(this, 'rec', newRec);

                    this.filterForms(val);
                }
            },
            rec: {
                handler: function() {
                    this.filterForms(this.form);
                },
                deep: true
            }
        }
    };
</script>
